import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";

import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import DocumentRenderer from "../../components/DocumentRenderer";
import { IDocProps } from "../../types/documents";
import axios from "axios";

const Insight: React.FC = (props) => {
  const { accessToken, clearIdentity, impersonation } = useAuthContext();
  const { id } = props.params;
  const [docObject, setDocObject] = useState<IDocProps | null>(null);

  const { data, isLoading, error } = useNorthstar(
    `/api/files/${id}`,
    accessToken,
    {
      method: "get",
    },
  );

  const getDocument = async (url: string, ext: string) => {
    if (!["txt", "md"].includes(ext)) return url;

    const headers: { [keyof: string]: string } = {
      "northstar-authorization": `Bearer ${accessToken}`,
    };

    if (impersonation) {
      headers["northstar-impersonate"] = impersonation;
    }

    const res = await axios.get(url, { headers });

    return res.data;
  };

  const createDocObject = async () => {
    const url = `${process.env.GATSBY_API_URL}/api/files/${id}/open`;
    setDocObject({
      url,
      document: await getDocument(url, data.data.attributes.ext),
      name: data.data.attributes.displayName || data.data.attributes.name,
      ext: data.data.attributes.ext,
      description: data.data.attributes.description,
      showAsDownloadable: data.data.attributes.isDownloadable,
    });
  };

  useEffect(() => {
    if (data) {
      createDocObject();
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    let ignore = true;

    if (error?.status === 401 && !ignore) clearIdentity();

    return () => {
      ignore = false;
    };
  }, [error?.status]);

  return (
    <NorthstarPageLayout title={"Insights"}>
      <>
        {isLoading || !data || !docObject ? (
          <Spinner active={true} />
        ) : (
          <DocumentRenderer docProps={docObject} />
        )}
      </>
    </NorthstarPageLayout>
  );
};

export default Insight;
